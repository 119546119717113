import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { defaultLanguage } from "@config/i18n";
import Information from "@modules/LearningHub/Information";
import { fetchCategories, fetchLatestPost } from "@redux/blog/actions";
import { wrapper } from "@redux/store";
import isDevelopment from "@utils/isDevelopment";
export const getStaticProps = wrapper.getStaticProps(store => async ({
  locale,
  params
}) => {
  if (!isDevelopment) {
    try {
      const page = params?.page && Number(params?.page);

      if (page === 1) {
        return {
          redirect: {
            destination: `/information`,
            permanent: true
          }
        };
      }

      await Promise.all([store.dispatch(fetchLatestPost({
        withLatestPost: true,
        page: Number(page ?? 1)
      })), store.dispatch(fetchCategories())]); // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  return {
    props: { ...(await serverSideTranslations(locale || defaultLanguage, ["components", "common", "blog"]))
    },
    revalidate: 10
  };
});
export default Information;