import { INFO_AND_NEWS_ARTICLE_CONTENT, INFO_AND_NEWS_CATEGORY_SELECTION, INFO_AND_NEWS_SEARCH } from "@const/moengage-analytic-event";
export const onArticleEventTrack = async (post: BlogPostModel | BlogActivePostModel) => {
  const {
    default: moengageAnalytic
  } = await import("@lib/moengage-analytic");
  moengageAnalytic.track(INFO_AND_NEWS_ARTICLE_CONTENT, {
    source: "Learning Hub",
    ...(post.post_tag ? {
      tag: post.post_tag
    } : {})
  });
};
export const onCategoryEventTrack = async (category: BlogCategoryModel) => {
  const {
    default: moengageAnalytic
  } = await import("@lib/moengage-analytic");
  moengageAnalytic.track(INFO_AND_NEWS_CATEGORY_SELECTION, {
    source: "Learning Hub",
    category_type: category.slug
  });
};
export const onSearchEventTrack = async () => {
  const {
    default: moengageAnalytic
  } = await import("@lib/moengage-analytic");
  moengageAnalytic.track(INFO_AND_NEWS_SEARCH);
};