import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Blog from "@layouts/v2/Blog";
import { getBreadcrumbsSchema } from "@utils/schema";
import { useCategories, useLatestPosts } from "./hooks/useInformation";
import { onArticleEventTrack, onCategoryEventTrack, onSearchEventTrack } from "./utils/event-tracking";
const baseUrl = "/information";

const Information = () => {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const page = Number(router.query.page ?? 1);
  const {
    categories,
    isLoading: categoriesLoading
  } = useCategories();
  const {
    posts: latestPosts,
    totalPages,
    isLoading: latestPostsLoading
  } = useLatestPosts(true, page);
  const latestPost = page === 1 ? latestPosts[0] : null;
  const posts = page === 1 && latestPosts.length > 0 ? latestPosts.slice(1) : latestPosts;
  const breadcrumbs = getBreadcrumbsSchema([{
    name: t("blog:title"),
    url: baseUrl
  }]);

  const handleSearch = (value: string) => {
    router.push(`${baseUrl}/search?q=${value}`);
    onSearchEventTrack();
  };

  return <Blog type='home' title={t("blog:title")} baseUrl={baseUrl} meta={{
    description: t("blog:subtitle"),
    breadcrumbs
  }} categories={{
    data: categories,
    isLoading: categoriesLoading,
    onClick: onCategoryEventTrack
  }} heroArticle={{
    data: latestPost,
    isLoading: latestPostsLoading,
    onClick: post => {
      if (post) onArticleEventTrack(post);
    }
  }} articles={{
    data: posts,
    isLoading: latestPostsLoading,
    onClick: onArticleEventTrack
  }} onSearch={handleSearch} pagination={{
    currentPage: page,
    totalPage: totalPages
  }} />;
};

export default Information;