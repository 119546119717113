import { PUBLIC_URL } from "@config/config";
/* eslint-disable import/prefer-default-export */

interface Item {
  name: string;
  url: string;
}
export const getBreadcrumbsSchema = (items: Item[]) => {
  const publicUrl = PUBLIC_URL || "https://reku.id";
  const itemListElement = [{
    type: "ListItem",
    position: 1,
    name: "Home",
    item: publicUrl
  }];
  items.forEach(item => {
    const position = itemListElement[itemListElement.length - 1].position + 1;
    itemListElement.push({
      type: "ListItem",
      position,
      name: item.name,
      item: `${publicUrl}${item.url}`
    });
  });
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement
  };
};