/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { fetchActivePost, fetchCategories, fetchLatestPost, fetchRelatedPosts, searchPosts } from "@redux/blog/actions";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
export const useLatestPosts = (withLatestPost: boolean = false, page: number = 1, categoryId: number | null = null) => {
  const {
    posts,
    totalPages,
    categoryId: currentCategoryId,
    currentPage,
    isLoading
  } = useAppSelector(state => state.blog.latestPosts);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (posts.length === 0 || categoryId !== currentCategoryId || currentPage !== page) {
      dispatch(fetchLatestPost({
        withLatestPost,
        page,
        categoryId
      }));
    }
  }, [dispatch, page, categoryId]);
  return {
    posts,
    totalPages,
    isLoading
  };
};
export const useActivePost = (slug: string) => {
  const {
    post,
    isLoading
  } = useAppSelector(state => state.blog.activePost);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (post === null || post.slug !== slug) {
      dispatch(fetchActivePost(slug));
    }
  }, [dispatch, slug]);
  return {
    post,
    isLoading
  };
};
export const useCategories = () => {
  const {
    categories,
    isLoading
  } = useAppSelector(state => state.blog.categories);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (categories.length === 0) {
      dispatch(fetchCategories());
    }
  }, [dispatch]);
  return {
    categories,
    isLoading
  };
};
export const useCategoryBySlug = (slug: string) => {
  const {
    categories,
    isLoading
  } = useCategories();
  const category = categories.find(e => e.slug === slug);
  return {
    category,
    isLoading
  };
};
export const useSearchPosts = (search: string, page: number = 1) => {
  const {
    posts,
    totalPages,
    isLoading
  } = useAppSelector(state => state.blog.searchPosts);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(searchPosts({
      search,
      page
    }));
  }, [dispatch, search, page]);
  return {
    posts,
    totalPages,
    isLoading
  };
};
export const useRelatedPosts = (categoryId: number) => {
  const {
    posts,
    currentCategoryId,
    isLoading
  } = useAppSelector(state => state.blog.relatedPosts);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (posts.length === 0 || currentCategoryId !== categoryId) {
      dispatch(fetchRelatedPosts(categoryId));
    }
  }, [dispatch, categoryId]);
  return {
    posts,
    isLoading
  };
};